
class Pallet
{
    caseNumber: string = "";

    constructor(caseNo: string) {
        this.caseNumber = caseNo;
    }
}

export default Pallet;